import useAppZustand from "@/stores/appZustand.ts";
import React, { useEffect } from "react";
import { fetchMe } from "@/lib/auth.ts";
import LoadingSpinner from "@/components/LoadingSpinner.tsx";
import { RouterProvider } from "react-router-dom";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";

type ApplicationViewProps = {
  router: RemixRouter;
};

export default function ApplicationView({ router }: ApplicationViewProps) {
  console.log("ApplicationView render");
  const { initialized, setInitialized, login } = useAppZustand(
    ({ initialized, setInitialized, login }) => ({
      initialized,
      setInitialized,
      login,
    }),
  );

  console.log("pre useEffect");
  useEffect(() => {
    (async () => {
      console.log("useEffect");
      if (initialized) return;
      console.log("initializing...");
      const me = await fetchMe();
      if (me !== null) {
        login(me);
      } else {
        setInitialized();
      }
      console.log("initializing complete");
    })();
  }, [initialized]);

  // Should not depend on user here, leads to race condition related to redirect

  if (!initialized) {
    return <LoadingSpinner />;
  }

  return <RouterProvider router={router} />;
}
