import React from "react";
import { ReactNode } from "react";

import { classNames } from "@/lib/styles";

type ButtonProps = {
  children: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "primary" | "secondary" | "outlined";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  id?: string;
};

const Button = ({
  children,
  type = "button",
  disabled = false,
  className,
  id,
  onClick,
  ...rest
}: ButtonProps) => (
  <button
    type={type}
    className={classNames(className || false, "btn-primary")}
    id={`${id}`}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
