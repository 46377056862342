import * as Sentry from "@sentry/react";

import { getIdTokenHeader } from "./auth";
import { IAccount, IOrganization, IUser, CreateAccountData, IInviteRow } from "@/types";
import { countries } from "@/lib/countries.ts";
import HttpConflictError from "@/lib/errors/HttpConflictError.ts";
import HttpAccessDeniedError from "@/lib/errors/HttpAccessDeniedError.ts";
import HttpGoneError from "@/lib/errors/HttpGoneError.ts";
import HttpError from "@/lib/errors/HttpError.ts";
import { InviteAccountRequest, InviteUserRequest, PendingInvite } from "@/types/invite.ts";

export const baseUrl = import.meta.env.VITE_WALLY_URL;

type RequestData = any;
type RequestConfig = {
  headers?: HeadersInit;
  expectedStatus?: number[];
  method?: string;
  raw?: boolean;
};

const wrappedFetch = async (
  path: string,
  data: RequestData,
  { headers, expectedStatus, method, raw }: RequestConfig,
) => {
  try {
    const res = await fetch(`${baseUrl}/${path}`, {
      method: method || "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        ...(headers || {}),
      },
      body: data ? JSON.stringify(data) : undefined,
    });

    if (raw) {
      return res;
    } else if ((expectedStatus && expectedStatus.includes(res.status)) || res.status === 200) {
      if (res.status === 204) return true;
      const response = await res.json();
      console.log(`Response for ${path}: `, response);
      return response;
    }

    let body = null;
    if (res.headers.get("Content-Type") === "application/json") {
      body = await res.json();
    }
    if (res.status === 409) throw new HttpConflictError(res, body);
    if ([401, 403].includes(res.status)) throw new HttpAccessDeniedError(res, body);
    if ([410].includes(res.status)) throw new HttpGoneError(res, body);
    throw new HttpError(res, body);
  } catch (err) {
    // Embarrassing, but compact
    Sentry.captureException(err);
    throw err;
  }
};
const create = async (path: string, data: RequestData, headers = {}) => {
  console.log(`Creating ${path} with data: `, data);
  return wrappedFetch(path, data, { headers, expectedStatus: [200, 201] });
};

const get = async (path: string, headers = {}) => {
  return wrappedFetch(path, null, { method: "GET", headers });
};

const patch = async (path: string, data: RequestData, headers = {}) => {
  console.log(`Patching ${path} with data: `, data);
  return wrappedFetch(path, data, {
    method: "PATCH",
    headers,
    expectedStatus: [200, 201],
  });
};

const remove = async (path: string, headers = {}) => {
  return wrappedFetch(path, null, { method: "DELETE", headers, expectedStatus: [200, 202, 204] });
};

export const createUser = async (data: IUser) => {
  return create("v2/user/", data);
};

export const getUser = async (id: string) => {
  return get(`v2/user/${id}`, getIdTokenHeader());
};

export const patchUser = async (id: string, data: RequestData) => {
  return patch(`v2/user/${id}`, data, getIdTokenHeader());
};

export const updatePassword = async (id: string, data: RequestData, token?: string) => {
  return wrappedFetch(`v2/user/${id}/password`, data, {
    headers: getIdTokenHeader(token),
    expectedStatus: [204],
  });
};

export const createBillingAccount = async (data: RequestData) => {
  return create("v2/billingAccount/", data, getIdTokenHeader());
};

export const getBillingAccounts = async (userId: string) => {
  return get(`v2/billingAccount/?userId=${userId}`, getIdTokenHeader());
};

export const patchBillingAccount = async (id: string, data: RequestData) => {
  return patch(`v2/billingAccount/${id}`, data, getIdTokenHeader());
};

export const createAccount = async (data: CreateAccountData) => {
  return create("v2/account/", data, getIdTokenHeader());
};

export const getAccount = async (id: string) => {
  const account = await get(`v2/account/${id}`, getIdTokenHeader());
  if (!account.timezone) {
    account.timezone = countries[account.countryCode]?.timezone ?? "UTC";
  }
  return account;
};

export const patchAccount = async (id: string, data: RequestData) => {
  return patch(`v2/account/${id}`, data, getIdTokenHeader());
};

export const getAccounts = async (): Promise<IAccount[]> => {
  return get("v2/user/accounts", getIdTokenHeader());
};

export const createOrganization = async (data: RequestData) => {
  return create("v2/organization/", data, getIdTokenHeader());
};

export const getOrganizations = async (): Promise<Array<IOrganization>> => {
  return get("v2/user/organizations", getIdTokenHeader());
};

export const getAccountsForOrganization = async (id: string): Promise<Array<IAccount>> => {
  return get(`v2/organization/${id}/accounts`, getIdTokenHeader()) as Promise<Array<IAccount>>;
};

export const getPendingInvitesForOrganization = async (id: string): Promise<Array<IInviteRow>> => {
  return get(`v2/invite/organization/${id}`, getIdTokenHeader()) as Promise<Array<IInviteRow>>;
};

export const getAccountAccess = (id: string) => {
  return get(`v2/account/${id}/access`, getIdTokenHeader());
};

type OrganizationsForAccountResponse = Promise<IOrganization[]>;
export const getOrganizationsForAccount = async (
  accountId: string,
): OrganizationsForAccountResponse => {
  return get(`v2/account/${accountId}/organizations`, getIdTokenHeader());
};

export const removeOrganizationFromAccount = (accountId: string, organizationId: string) => {
  console.error("removeOrganizationFromAccount probably not implemented on backend?");
  // return remove(`v2/organization/${accountId}/organizations/${organizationId}`, getIdTokenHeader());
};

type UsersForAccountResponse = Promise<IUser[]>;
export const getUsersForAccount = async (accountId: string): UsersForAccountResponse => {
  return get(`v2/account/${accountId}/users`, getIdTokenHeader());
};

type UsersForOrganizationResponse = Promise<IUser[]>;
export const getUsersForOrganization = async (
  organizationId: string,
): UsersForOrganizationResponse => {
  return get(`v2/organization/${organizationId}/users`, getIdTokenHeader());
};

export const removeUserFromOrganization = (organizationId: string, userId: string) => {
  return remove(`v2/organization/${organizationId}/users/${userId}`, getIdTokenHeader());
};

export const removeUserFromAccount = (accountId: string, userId: string) => {
  return remove(`v2/account/${accountId}/users/${userId}`, getIdTokenHeader());
};

export const getInvite = (inviteId: string): Promise<PendingInvite> => {
  return wrappedFetch(`v2/invite/${inviteId}`, undefined, {
    method: "GET",
    expectedStatus: [200],
  }) as Promise<PendingInvite>;
};

export const inviteUserToOrganization = (organizationId: string, data: InviteUserRequest) => {
  return create(`v2/invite/organization/${organizationId}/user`, data, getIdTokenHeader());
};

export const inviteAccountToOrganization = (organizationId: string, data: InviteAccountRequest) => {
  return create(`v2/invite/organization/${organizationId}/account`, data, getIdTokenHeader());
};

export const inviteUserToAccount = (accountId: string, data: InviteUserRequest) => {
  return create(`v2/invite/account/${accountId}/user`, data, getIdTokenHeader());
};

export const deduplicateUser = async ({ type, scope, key }: RequestData) => {
  const res = await wrappedFetch("v2/deduplicate/user/", { type, scope, key }, { raw: true });
  if (res.status === 204) return true;
  if (res.status === 409) return false;
  throw new Error(`Unexpected status (${res.status}) while checking ${type} ${scope} ${key}`);
};

export const requestChallenge = async (values: { email: string }) => {
  console.log("Resetting identifier", values, baseUrl);
  return wrappedFetch("v2/reset/identifier", values, { raw: true });
};

export const respondToChallenge = async ({
  challenge,
  response,
}: {
  challenge: string;
  response: string;
}) => {
  console.log("Responding to challenge", { challenge, response }, baseUrl);
  return wrappedFetch(`v2/reset/challenge/${challenge}`, { response }, { raw: true });
};

const cachedAccessTokens: any = {};

type GetAccessTokenProps = { accountId: string; idToken: string };
export const getAccessToken = async ({ accountId, idToken }: GetAccessTokenProps) => {
  if (cachedAccessTokens[accountId]) {
    const { token, expiresAt } = cachedAccessTokens[accountId];
    if (expiresAt > Date.now()) {
      return token;
    }
  }
  try {
    const res = await fetch(`${baseUrl}/v2/authorize/account/${accountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (res.status === 200) {
      const { token } = await res.json();
      const cacheControlStr = res.headers.get("Cache-Control");
      if (cacheControlStr && cacheControlStr.includes("max-age")) {
        const execResult = /max-age=(?<maxAge>\d+)$/.exec(cacheControlStr);
        if (execResult) {
          const [, maxAgeStr] = execResult;
          cachedAccessTokens[accountId] = {
            token,
            expiresAt: Date.now() + (Number.parseInt(maxAgeStr, 10) - 10) * 1000,
          };
        }
        return token;
      }
      console.warn('No "Cache-Control" header with max-age found in access token response');
      return token;
    } else {
      const message = await res.json();
      throw new Error(`Unexpected status (${res.status}) "${JSON.stringify(message)}"`);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};
