import { ReactNode } from "react";

import Logo from "@/components/Logo";
import LangSelect from "@/components/i18n/LangSelect";

type CenteredLayoutProps = { children: ReactNode };
export default function CenteredLayout({ children }: CenteredLayoutProps) {
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="m-2 sm:m-0 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex flex-row justify-between items-center w-full">
          <Logo className="h-12 w-auto" />
          <LangSelect />
        </div>
        {children}
      </div>
    </div>
  );
}
