import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { clearPendingRedirect } from "@/lib/pending_redirects";
import Button from "@/components/button/Button.tsx";
import CenteredLayout from "@/layouts/CenteredLayout.tsx";

export const ErrorBoundary = () => {
  const error: any = useRouteError();

  Sentry.captureException(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      clearPendingRedirect();
    }
  }

  return (
    <CenteredLayout>
      <h1 className="text-3xl mt-6">Det har oppstått en feil :(</h1>
      <div className="mt-2 mb-2">
        Feilmelding: <pre>{error.message}</pre>
      </div>

      <div className="mt-6">
        Forsøk å{" "}
        <Button
          className="btn-secondary"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          gå til forsiden
        </Button>
        <span> eller </span>
        <Button
          className={"btn-secondary"}
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          }}
        >
          nullstill applikasjonen
        </Button>
      </div>
      <div className="mt-6">
        Å nullstille applikasjonen vil slette midlertidige data i nettleseren og logge deg ut.
      </div>
      <div className="mt-6">
        Send en e-post til kontakt@jordplan.no hvis du ikke klarer å bli kvitt feilen.
      </div>
    </CenteredLayout>
  );
};
