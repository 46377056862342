import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import AnonymousRoutes from "./components/AnonymousRoutes";
import { ErrorBoundary } from "./components/ErrorBoundary";
import LoadingSpinner from "./components/LoadingSpinner";
import LogoutRoute from "./components/LogoutRoute";
import PrivateRoutes from "./components/PrivateRoutes";
import "./i18n/config";
import "./index.css";
import SidebarLayout from "./layouts/SidebarLayout";
import { fetchMe } from "./lib/auth";
import useAppZustand from "./stores/appZustand";
import { loadData } from "@/lib/manifold.ts";
import { IAccountListRow } from "@/types/accountListRow.ts";
import { IOrganization } from "@/types";
import HttpGoneError from "@/lib/errors/HttpGoneError.ts";
import HttpError from "@/lib/errors/HttpError.ts";
import RegionPage from "@/pages/register";

// import UserContext from './context/User';

import "@/App.css";
import ApplicationView from "@/components/ApplicationView.tsx";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";

const Front = React.lazy(() => import("./pages/front"));

const router: RemixRouter = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "",
        element: <SidebarLayout />,
        children: [
          {
            path: "",
            lazy: () => import("./pages/front"),
          },
          {
            path: "/user/:id/edit",
            lazy: () => import("./pages/user/AccountPage"),
          },
          {
            path: "/user/:id/security",
            lazy: () => import("./pages/user/SecurityAccountPage"),
          },
          {
            path: "/user/:id/billing",
            lazy: () => import("./pages/user/BillingAccountPage"),
          },
          {
            path: "/account/:id/settings",
            lazy: () => import("./pages/account/AccountSettingsPage"),
          },
          {
            path: "/account/:id/users",
            lazy: () => import("./pages/account/AccountUsersPage"),
          },
          {
            path: "/account/:id/organizations",
            lazy: () => import("./pages/account/AccountOrganizationsPage"),
          },
          {
            path: "/map/:accountId",
            lazy: () => import("./pages/map"),
          },
          {
            path: "/organization",
            lazy: () => import("./pages/organization/OrganizationCreatePage"),
          },
          {
            path: "/organization/:organizationId/accounts",
            lazy: () => import("./pages/organization/OrganizationAccountsPage"),
          },
          {
            path: "/organization/:organizationId/users",
            lazy: () => import("./pages/organization/OrganizationUsersPage"),
          },
          {
            path: "/organization/:organizationId/settings",
            lazy: () => import("./pages/organization/OrganizationSettingsPage"),
          },
        ],
      },
    ],
  },
  {
    path: "/logout",
    lazy: () => import("./components/LogoutRoute.tsx"),
  },
  {
    path: "/register",
    element: <AnonymousRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/register",
        lazy: () => import("./pages/register"),
      },
      {
        path: "/register/no",
        lazy: () => import("./pages/register/no"),
      },
      {
        path: "/register/se",
        lazy: () => import("./pages/register/se"),
      },
    ],
  },
  {
    path: "/auth",
    element: <AnonymousRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/auth/reset",
        lazy: () => import("./pages/auth/reset"),
      },
      {
        path: "/auth/login",
        lazy: () => import("./pages/auth/login"),
      },
    ],
  },
  {
    path: "/build/no/",
    element: <PrivateRoutes />, // TODO Keep both user ids in case of referral
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "account",
        lazy: () => import("./pages/build/no/account"),
      },
      {
        path: "fields/:accountId",
        lazy: () => import("./pages/build/no/fields"),
      },
    ],
  },
  {
    path: "/build/se/",
    element: <PrivateRoutes />, // TODO Keep both user ids in case of referral
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "fields/:accountId",
        lazy: () => import("./pages/build/se/fields"),
      },
    ],
  },
  {
    path: "/inv/:inviteId",
    lazy: () => import("./pages/invite/InvitePage"),
  },
]);

function useNavigationType() {}

Sentry.init({
  dsn: "https://e2defc9fd36a48a2adc1d19f584a8491@o375090.ingest.sentry.io/4504346096238592",
  environment: "development",
  integrations: [
    // @ts-ignore
    new Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      // useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.05,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApplicationView router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
